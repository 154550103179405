import React, { PropTypes } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { StaticImage } from "gatsby-plugin-image"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from "gatsby"
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CardCheckout from './CardCheckout';

const promise = loadStripe('pk_test_51HkBCKKRsegBlMw5tVq2vumGwymw0cVL9FaRZVyi8J9jkBTLh95aDq3u9S6vuc6HAi2a8523OfbUIlkZsTzLSRnn00tKUo9evR');
const CardDialogue = props => {
  return (
    <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="Card-dialogue-title">
          {"PCI Compliant Card"}
        </DialogTitle>
        <DialogContent>
          <Elements stripe={promise}>
            <CardCheckout />
          </ Elements>
        </DialogContent>
        <DialogActions>
          <ThemeProvider theme={props.muiTheme}>
            <Button onClick={props.handleClose}>Close</Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
  );
};

export default CardDialogue;