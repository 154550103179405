import React, { PropTypes } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { StaticImage } from "gatsby-plugin-image"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from "gatsby"

const VenmoDialogue = props => {
  return (
    <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="venmo-dialogue-title" className="pay-title">
          {"Venmo"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{paddingBottom: '10px', textAlign: 'center'}} id="venmo-dialog-description" className="pay-description">
            @galvanitic
          </DialogContentText>
          <Link to="https://venmo.com/u/galvanitic" target="_blank">
            <div className="qr-code">
              <StaticImage
                src="../static/images/qr_venmo.jpg"
                alt="Venmo QR Code" 
                placeholder="blurred"
                layout="constrained"
                width={200}
                height={200}/>
            </div>
          </Link>
        </DialogContent>
        <DialogActions>
          <ThemeProvider theme={props.muiTheme}>
            <Button onClick={props.handleClose}>Close</Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
  );
};

export default VenmoDialogue;