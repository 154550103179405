import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Slider from "react-slick";

const Affiliation = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 9,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 0,
    cssEase: "linear",
    centerMode: true,
    pauseOnHover: false
  };
  return ( 
    <div className="affiliation">
      <Slider {...settings}>
        <a href="https://pelotonu.org">
          <StaticImage
            src="../static/images/pelotonu.png"
            alt="PelotonU"
            placeholder="blurred"
            layout="constrained"
            width={210}
            height={48}
          />
        </a>

        <a href="https://www.txstate.edu/">
          <StaticImage
            src="../static/images/txst.png"
            alt="Texas State University"
            placeholder="blurred"
            layout="constrained"
            width={110}
            height={110}
          />
        </a>

        <a href="https://codigoaustin.org">
          <StaticImage
            src="../static/images/ca.png"
            alt="Código Austin"
            placeholder="blurred"
            layout="constrained"
            width={110}
            height={110}
          />
        </a>

        <a href="https://exploreaustin.org">
          <StaticImage
            src="../static/images/ea.png"
            alt="Explore Austin"
            placeholder="blurred"
            layout="constrained"
            width={196}
            height={90}
          />
        </a>

        <a href="https://diversity.utexas.edu/innovation/programs/product-prodigy/">
          <StaticImage
            src="../static/images/ppi.png"
            alt="Product Prodigy Institute"
            placeholder="blurred"
            layout="constrained"
            width={196}
            height={87}
          />
        </a>

        <a href="https://kipptexas.org/">
          <StaticImage
            src="../static/images/kt.png"
            alt="KIPP: Texas"
            placeholder="blurred"
            layout="constrained"
            width={196}
            height={80}
          />
        </a>

        <a href="https://logictry.com/">
          <StaticImage
            src="../static/images/logictry.svg"
            alt="Logictry"
            placeholder="blurred"
            layout="constrained"
            width={196}
            height={57}
          />
        </a>

        <a href="https://www.dctf-austin.org/">
          <StaticImage
            src="../static/images/dctf.webp"
            alt="The Dream Come True Foundation"
            placeholder="blurred"
            layout="constrained"
            width={196}
            height={78}
          />
        </a>

        <a href="https://austincodingacademy.com/">
          <StaticImage
            src="../static/images/aca.png"
            alt="Austin Coding Academy"
            placeholder="blurred"
            layout="constrained"
            width={110}
            height={110}
          />
        </a>
      </Slider>
    </div>
   );
}
 
export default Affiliation;