import * as React from "react"
import "../styles/slick.css"; 
import "../styles/slick-theme.css";
import '../styles/normalize.css'
import '../styles/main.scss'
import Nav from '../components/Navigation'
import Footer from "../components/Footer"
import Affiliation from "../components/Affiliations"
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FavoriteIcon from '@mui/icons-material/Favorite';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { StaticImage } from "gatsby-plugin-image"
import SEO from '../components/SEO'
// import CardDialogue from '../components/CardDialogue'
import VenmoDialogue from "../components/VenmoDialogue";
import CashAppDialogue from "../components/CashAppDialogue";
import PayPalDialogue from "../components/PayPalDialogue";
import { IoLogoVenmo } from "@react-icons/all-files/io5/IoLogoVenmo";
import { SiCashapp } from "@react-icons/all-files/si/SiCashapp";
import { IoLogoPaypal } from "@react-icons/all-files/io5/IoLogoPaypal";
import { FaCreditCard } from "@react-icons/all-files/fa/FaCreditCard";
import { FaMoneyBillAlt } from "@react-icons/all-files/fa/FaMoneyBillAlt";
import { GiPayMoney } from "@react-icons/all-files/gi/GiPayMoney";
import CardDialogue from "../components/CardDialogue";

const favor = createTheme({
  palette: {
    primary: {
      main: '#a53648',
    },
  },
});
// markup
const IndexPage = () => {
  const [openCard, setOpenCard] = React.useState(false)
  const handleCardOpen = () => {
    setOpenCard(true);
  };
  const handleCardClose = () => {
    setOpenCard(false);
  };

  const [openVenmo, setOpenVenmo] = React.useState(false)
  const handleVenmoOpen = () => {
    setOpenVenmo(true);
  };
  const handleVenmoClose = () => {
    setOpenVenmo(false);
  };

  const [openCashApp, setOpenCashApp] = React.useState(false)
  const handleCashAppOpen = () => {
    setOpenCashApp(true);
  };
  const handleCashAppClose = () => {
    setOpenCashApp(false);
  };

  const [openPayPal, setOpenPayPal] = React.useState(false)
  const handlePayPalOpen = () => {
    setOpenPayPal(true);
  };
  const handlePayPalClose = () => {
    setOpenPayPal(false);
  };
  const actions = [
    // { icon: <FaCreditCard />, 
    //           name: 'PCI Compliant Card Payment', 
    //           handler: handleCardOpen },
    { icon: <IoLogoVenmo />, 
              name: 'Venmo',
              handler: handleVenmoOpen },
    { icon: <SiCashapp />, 
              name: 'CashApp',
              handler: handleCashAppOpen },
    { icon: <IoLogoPaypal />, 
              name: 'PayPal',
              handler: handlePayPalOpen },
  ];
  return (
    <main>
      <SEO favicon="../static/images/favicon.ico"/>
      <Nav />
      <div id="landing">
        <div className="image">
          <StaticImage
            src="../static/images/profile-july-2021.jpg"
            alt="Rodolfo Galván"
            placeholder="blurred"
            layout="fullWidth"
            width={400}
            height={400}
          />
        </div>
        <div className="left">
          <p className="text text1">Hecho en <a href="https://earth.google.com/web/search/Le%c3%b3n,+Guanajuato,+Mexico/@21.1219138,-101.66601145,1806.83261681a,34696.28963881d,35y,0h,60t,0r/data=CoQBGloSVAolMHg4NDJiYmVmZmQ2ZTZlNzIxOjB4NjA2ZDk1ZWM2MWE1NDMyNxmIPy-BACA1QCFCdt7G5mtZwCoZTGXDs24sIEd1YW5hanVhdG8sIE1leGljbxgCIAEiJgokCSeldXjVAzZAEZtLz4pM4zRAGedKguCILVnAIdOrMm3Ue1nAKAI" target="_blank"><u>León</u></a> -</p>
          <ul>
            <li>diversidad</li>
            <li>equidad</li>
            <li>inclusión</li>
            <li>filantropía</li>
            <li>educación</li>
            <li>derechos</li>
          </ul>
        </div>
        <div className="right">
          <p className="text text2">- Designed in <a href="https://earth.google.com/web/search/Austin,+TX/@30.3077609,-97.7534014,190.63795243a,64223.29330967d,35y,360h,60t,0r/data=CnUaSxJFCiUweDg2NDRiNTk5YTBjYzAzMmY6MHg1ZDliNDY0YmQ0NjlkNTdhGT0HliNkRD5AISK84k6Ob1jAKgpBdXN0aW4sIFRYGAIgASImCiQJwpBdtQWyNUAR4Ql9iWDbNEAZKMwNardgWcAhIpvTa9yVWcAoAg" target="_blank"><u>Austin</u></a></p>
          <ul>
            <li>community</li>
            <li>technology</li>
            <li>STEAM</li>
            <li>comp. eng.</li>
            <li>product</li>
            <li>comp. sci.</li>
          </ul>
        </div>
      </div>
      <Affiliation />
      <ThemeProvider theme={favor}>
        <SpeedDial
          ariaLabel="Make a Gift"
          sx={{ position: 'fixed', bottom: 16, right: 16}}
          icon={<GiPayMoney style={{fontSize: '35px'}} openicon={<VolunteerActivismIcon />} />}
          >
          {actions.map((action) => (
            <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.handler}
            />
            ))}
        </SpeedDial>
      </ThemeProvider>
      {/* <CardDialogue muiTheme={favor} open={openCard} handleClose={handleCardClose}/> */}
      <VenmoDialogue muiTheme={favor} open={openVenmo} handleClose={handleVenmoClose}/>
      <CashAppDialogue muiTheme={favor} open={openCashApp} handleClose={handleCashAppClose} />
      <PayPalDialogue muiTheme={favor} open={openPayPal} handleClose={handlePayPalClose} />
      <Footer />
    </main>
  )
}

export default IndexPage
